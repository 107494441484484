.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.personas-table {
  margin-top: 20px;
  border-collapse: collapse;
  width: 80%;
  color: #282c34;
  background-color: white;
}

.personas-table th, .personas-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.personas-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #282c34;
  color: white;
}

.personas-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.personas-table tr:hover {
  background-color: #ddd;
}
